import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import FormInput from "../../components/FormInput"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import Sidebar from "../../components/CompanyProfileSidebar"
import ImageDefault from "../../assets/images/default-hero-bg.jpg"
import logoDefault from "../../assets/images/candidate-profile-placeholder.png"
import Modal from "../../components/Modal"
import ModalModules from "../../components/ModalModules"
import { MultiSelect } from "react-multi-select-component"
import {CATEGORIES_LIST} from "../../constants/categories";

const MemberProfile = ({ location, userName, logoImage, backgroundImage, msRolesJSONData, msRolesStringData, rolesTags }) => {
  const [data, setMemberData] = useState("")
  const logoModal = useRef()
  const headerModal = useRef()
  const [selectRoles, setRolesSelected] = useState([])

  const [transferedImage, setTransferedImage] = useState("")
  const [transferedColor, setTransferedColor] = useState("")
  const [transferedBgImage, setTransferedBgImage] = useState("")
  const [transferedBgColor, setTransferedBgColor] = useState("")

  const [selectPerks, setPerksSelected] = useState([])

  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then(function (member) {
          setMemberData(member)
        })
        .catch((e) => {
          console.log(e)
        })
    }

    if (data) {
      if (data["roles-seeking"]) {
        msRolesStringData = data["roles-seeking"]
        try {
          msRolesJSONData = JSON.parse(msRolesStringData)
          console.log(msRolesJSONData)
          setRolesSelected([...msRolesJSONData])
        } catch {}
      }
    }

    let divs = document.querySelectorAll("[data-ms-content]")

    ;[].forEach.call(divs, function (div) {
      div.classList.add("ms-ready")
    })
  }, [data["roles-seeking"]])

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Company Profile Edit",
  })

  let membershipName

  if (data) {
    membershipName = data.membership.name
  }

  if (data["first-name"]) {
    userName = data["first-name"] + " " + data["last-name"]
  } else {
    userName = "Candidate"
  }

  if (data.logo) {
    logoImage = <img src={data.logo} alt={data["company-name"]} />
  } else {
    logoImage = <img src={logoDefault} alt={data["company-name"]} />
  }

  if (data["background-image"]) {
    backgroundImage = <img src={data["background-image"]} alt={data["company-name"]} />
  } else {
    backgroundImage = <img src={ImageDefault} alt={data["company-name"]} />
  }

  const imageData = [
    {
      logo: {
        src: data.logo,
        bgcolor: data["logo-background-color"],
        class: "uploaded-image--logo",
        width: 298,
        height: 298,
      },
    },
    {
      header: {
        src: data["background-image"],
        bgcolor: data["background-color"],
        class: "uploaded-image--header",
        width: 1280,
        height: 400,
      },
    },
  ]

  const rolesSeeking = CATEGORIES_LIST;

  const years = [
    { label: "1-5", value: "1-5" },
    { label: "5-10", value: "5-10" },
    { label: "10-20", value: "10-20" },
    { label: "20+", value: "20+" },
  ]
  if (transferedImage) {
    data.logo = transferedImage
  }
  if (transferedColor) {
    data["logo-background-color"] = transferedColor
  }

  if (transferedBgImage) {
    data["background-image"] = transferedBgImage
  }
  if (transferedBgColor) {
    data["background-color"] = transferedBgColor
  }
  if (data.logo) {
    logoImage = <img src={data.logo} alt={data["company-name"]} />
  } else {
    logoImage = <img src={logoDefault} alt={data["company-name"]} />
  }

  if (data["background-image"]) {
    backgroundImage = <img src={data["background-image"]} alt={data["company-name"]} />
  } else {
    backgroundImage = <img src={ImageDefault} alt={data["company-name"]} />
  }

  const pronouns = [
    { label: "He/Him/His/Himself", value: "He/Him/His/Himself" },
    { label: "She/Her/Hers/Herself", value: "She/Her/Hers/Herself" },
    { label: "They/Them/Their/Theirs/Themself", value: "They/Them/Their/Theirs/Themself" },
  ]

  return (
    <Layout>
      <form data-ms-form="profile">
        <Seo title="Member Profile" />
        <Breadcrumbs crumbs={crumbs} />

        <div className="profiles profiles--edit container">
          <div className="row profile__layout">
            <Sidebar sidebar={data} />

            <div className="content">
              <div className="content__header">
                <h1>Welcome {userName}!</h1>
                <h3>{membershipName} Member</h3>

                <div className="upload--file">
                  <div className="uploaded--file">
                    <input type="hidden" data-ms-member="background-image" defaultValue={data["background-image"]} />
                    <input type="hidden" data-ms-member="background-color" defaultValue={data["background-color"]} />
                    <label htmlFor="upload-background-image" className="image--file image--file__background" onClick={() => headerModal.current.openModal()} style={{ backgroundColor: `${data["background-color"]}` }} data-ms-content="not-allowed">
                      <img src={data["background-image"]} alt={data["company-name"]} id="backgroundImage" />
                      {backgroundImage}
                      <i className="fas fa-image"></i>
                    </label>
                    <label htmlFor="upload-logo" className="image--file image--file__logo" onClick={() => logoModal.current.openModal()} style={{ backgroundColor: `${data["logo-background-color"]}` }}>
                      <input type="hidden" data-ms-member="logo" defaultValue={data.logo} />
                      <input type="hidden" data-ms-member="logo-background-color" defaultValue={data["logo-background-color"]} />
                      {logoImage}
                      <i className="fas fa-camera"></i>
                    </label>
                  </div>
                </div>
              </div>
              <h3>Profile Information</h3>
              <div className="profiles__forms">
                <div className="profiles__forms--cols">
                  <FormInput name="first-name" type="text" required={true} label="First Name" memberstack="first-name" />
                  <FormInput name="last-name" type="text" required={true} label="Last Name" memberstack="last-name" />

                  <div className="form-field dropdown ">
                    <label htmlFor="pronouns">Pronouns</label>
                    <div className="select-container">
                      <div className="select-container__select">
                        <select name="pronouns" id="pronouns" data-ms-member="pronouns">
                          <option value="">- Select -</option>
                          {pronouns.map((e, i) => {
                            return (
                              <option defaultValue={e.value} key={i}>
                                {e.label}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <FormInput name="contact-email" type="email" required={true} label="Your Email" memberstack="contact-email" placeholder="ex. name@domain.com" />
                  <FormInput type="tel" id="phonenumber" name="phonenumber" label="Phone Number" memberstack="phone-number" placeholder="ex. 262-123-4567" maxlength="12" />
                </div>
                <div className="profiles__forms--cols">
                  <div className="form-field dropdown multiselect">
                    <label htmlFor="rolesSeeking">What roles are you seeking?</label>
                    <input id="hidden-perks-input" type="hidden" defaultValue={JSON.stringify(selectRoles)} data-ms-member="roles-seeking" />
                    <MultiSelect id="rolesSeeking" name="roles_seeking" required options={rolesSeeking} value={selectRoles} onChange={setRolesSelected} overrideStrings={{ selectSomeItems: "- Select -" }} />
                  </div>

                  <div className="form-field dropdown ">
                    <label htmlFor="years-experience-in-tech">Years of Experience</label>
                    <div className="select-container">
                      <div className="select-container__select">
                        <select name="years-experience-in-tech" id="years-experience-in-tech" data-ms-member="years-experience-in-tech">
                          <option value="">- Select -</option>
                          {years.map((e, i) => {
                            return (
                              <option defaultValue={e.value} key={i}>
                                {e.label}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <FormInput name="portfolio-github-url" type="text" label="Portfolio / GitHub URL" memberstack="portfolio-github-url" />
                  <FormInput name="linkedin-or-other-profile" type="text" label="LinkedIn or Other Profiles" memberstack="linkedin-or-other-profile" />
                </div>
              </div>

              <div className="form-controls">
                <button type="submit" className="button button--primary">
                  Save Profile
                </button>
                <Link to="/candidate/member-profile" className="link">
                  Back to My Profile
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal ref={logoModal}>
        <ModalModules ref={logoModal} formDisplayed="logoUpload" data={imageData[0].logo} transferColor={(transferedColor) => setTransferedColor(transferedColor)} transferImage={(transferedImage) => setTransferedImage(transferedImage)} />
      </Modal>
      <Modal ref={headerModal}>
        <ModalModules ref={headerModal} formDisplayed="headerUpload" data={imageData[1].header} transferColor={(transferedBgColor) => setTransferedBgColor(transferedBgColor)} transferImage={(transferedBgImage) => setTransferedBgImage(transferedBgImage)} />
      </Modal>
    </Layout>
  )
}

export default MemberProfile
